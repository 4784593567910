import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import TypeAnimation from 'react-type-animation';

const steps = [
    'Digital Advertising', 3000,
    'Digital Advertising Campaign Management', 2000,
    'Digital Advertising Campaign Growth Marketing', 2000,
    'Digital Advertising Campaign SEM and PPC Management', 2000,
    'Digital Advertising Campaign Social Advertising Campaigns', 2000,
    'Whatever You Call It.', 4000
];

const ContentPage = () => (
    <Layout>
        <SEO title="Digital Advertising "/>
        <section className="page-heading">
            <div className="container">
                <TypeAnimation
                    cursor={false}
                    sequence={steps}
                    wrapper="h1"
                    className={'title'}
                />
            </div>
        </section>
        <section className="web-it-support">
            <div className="container">
                <Products>
                    <div title="Launch Project" subtitle="$2,000 per month + your advertising budget"
                         id="advertising-launch">
                        <p>Whatever you call it, the goal is always going to be one of the following: You want more
                            customers. You want to keep your customers loyal. You want to have your customers spend more
                            money with you. We do those things for your brand and business. And we do them through
                            digital advertising channels that are measurable, scalable and targeted.</p>
                        <p><strong>This is a 3-month agreement as you can not expect to only run ads for one month and
                            be successful.</strong></p>
                        <p>We take your advertising creative and identified audience to the recommended channels,
                            launch, monitor and optimize the targeting/spend and provide feedback on the messaging and
                            creative to improve for the next ad-cycle.</p>
                        <p>We provide a weekly highlight reel of the campaign + a bi-weekly summary of adjustments and
                            optimizations + a monthly debrief on the campaign, evaluating and scoring the results +
                            pointing to opportunity, learning and changes that need to happen with the creative, budget,
                            targeting and channel mix.</p>
                    </div>
                    <div title="Grow Project" subtitle="$4,000 per month + your advertising budget"
                         id="advertising-grow">
                        <p><strong>This is a 3-month agreement. This is strategic, not gambling with your
                            business.</strong> We take your idea and message and develop the creative that will connect
                            with your identified audience and launch the advertising on the recommended channels.</p>
                        <p>We provide a weekly highlight reel of the campaign + a bi-weekly summary of adjustments and
                            optimizations and new ad-flights/creative + a monthly debrief on the campaign, evaluating
                            and scoring the results + pointing to opportunity, learning and changes that need to happen
                            with the creative, budget, targeting and channel mix.</p>
                    </div>
                    <div title="Build Project" subtitle="$8,000 per month + your advertising budget"
                         id="advertising-build">
                        <p><strong>This is a 3 month agreement, but really you should be planning for a full
                            year.</strong> We develop the plan, the creative (visuals and copy) and the basic marketing funnel (landing
                            pages + email flows) to take your campaigns to the next level.</p>
                        <p>We provide a weekly highlight reel of the campaign + a bi-weekly summary of adjustments and
                            optimizations and new ad-flights/creatives + a monthly debrief on the campaign, evaluating
                            and scoring the results + pointing to opportunity, learning and changes that need to happen
                            with the creative, budget, targeting and channel mix.</p>
                    </div>
                </Products>
            </div>
        </section>
    </Layout>
)

export default ContentPage;
